import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import "./utils/index.js"

Vue.config.productionTip = false
Vue.use(ElementUI);

	const mymixin = {
		created() {
			if (this.$store?.commit) {
				this.$store.commit('getRouterList',this.$route.path)
			}
			// console.log(123456);
			
		}
	}
	Vue.mixin(mymixin);
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
