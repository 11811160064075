import request from './request'


//数据列表
export function login(params) {
	return request({ url: '/common/login',method: 'post',data: params})
}

//线索|商机|客户列表
export function customerList(params) {
	return request({ url: '/customer/customerList',method: 'get',data: params})
}

//公司所有设置项列表
export function allSettingList(params) {
	return request({ url: '/setting/allSettingList',method: 'get',data: params})
}

//公司某项设置列表
export function settingList(params) {
	return request({ url: '/setting/settingList',method: 'get',data: params})
}

//获取日程列表
export function scheduleList(params) {
	return request({ url: '/schedule/scheduleList',method: 'get',data: params})
}

// 添加日程
export function addSchedule(params) {
	return request({ url: '/schedule/addSchedule',method: 'post',data: params})
}

// 删除日程
export function deleteSchedule(params) {
	return request({ url: '/schedule/deleteSchedule',method: 'get',data: params})
}

// 获取日程详情
export function getScheduleDetail(params) {
	return request({ url: '/schedule/scheduleDetail',method: 'get',data: params})
}

// 编辑日程
export function editSchedule(params) {
	return request({ url: '/schedule/editSchedule',method: 'post',data: params})
}

// 获取所有员工列表
export function allUserList(params) {
	return request({ url: '/staff/allUserList',method: 'get',data: params})
}

// 添加线索(客户)
export function addCustomerInfo(params) {
	return request({ url: '/customer/addCustomerInfo',method: 'post',data: params})
}

// 线索详情(客户)
export function customerInfo(params) {
	return request({ url: '/customer/customerInfo',method: 'get',data: params})
}

// 指定某个客户的商机列表
export function customerChanceList(params) {
	return request({ url: '/customer/customerChanceList',method: 'get',data: params})
}

// 新增客户商机
export function addCustomerChance(params) {
	return request({ url: '/customer/addCustomerChance',method: 'post',data: params})
}

//指定某个客户的跟进记录
export function customerFollowList(params) {
	return request({ url: '/customer/customerFollowList',method: 'get',data: params})
}

// 添加跟进记录
export function addCustomerFollow(params) {
	return request({ url: '/customer/addCustomerFollow',method: 'post',data: params})
}

// 线索基本信息编辑
export function editCustomerInfo(params) {
	return request({ url: '/customer/editCustomerInfo',method: 'post',data: params})
}

// 删除客户
export function deleteCustomer(params) {
	return request({ url: '/customer/deleteCustomer',method: 'get',data: params})
}

// 获取合同列表
export function contractList(params) {
	return request({ url: '/contract/contractList',method: 'get',data: params})
}

// 添加合同
export function addContract(params) {
	return request({ url: '/contract/addContract',method: 'post',data: params})
}

// 编辑内容设置
export function editSetting(params) {
	return request({ url: '/setting/editSetting',method: 'post',data: params})
}

// 添加内容设置
export function addSetting(params) {
	return request({ url: '/setting/addSetting',method: 'post',data: params})
}

// 删除内容设置
export function deleteSetting(params) {
	return request({ url: '/setting/deleteSetting',method: 'get',data: params})
}

// 转入公海
export function shiftCustomerToSea(params) {
	return request({ url: '/customer/shiftCustomerToSea',method: 'get',data: params})
}

// 领取客户
export function receiveCustomer(params) {
	return request({ url: '/customer/receiveCustomer',method: 'get',data: params})
}

// 新增客户
export function addCustomer(params) {
	return request({ url: '/customer/addCustomer',method: 'post',data: params})
}

// 部门列表
export function departmentList(params) {
	return request({ url: '/department/departmentList',method: 'get',data: params})
}

// 部门树
export function departmentTree(params) {
	return request({ url: '/department/departmentTree',method: 'get',data: params})
}

// 添加部门
export function addDepartment(params) {
	return request({ url: '/department/addDepartment',method: 'post',data: params})
}

// 修改部门
export function editDepartment(params) {
	return request({ url: '/department/editDepartment',method: 'post',data: params})
}

// 删除部门
export function deleteDepartment(params) {
	return request({ url: '/department/deleteDepartment',method: 'get',data: params})
}

// 获取角色列表
export function roleList(params) {
	return request({ url: '/role/roleList',method: 'get',data: params})
}

// 添加角色
export function addRole(params) {
	return request({ url: '/role/addRole',method: 'post',data: params})
}

// 获取权限树
export function permissionTree(params) {
	return request({ url: '/role/permissionTree',method: 'get',data: params})
}

// 获取角色权限
export function userPermissionTree(params) {
	return request({ url: '/staff/userPermissionTree',method: 'get',data: params})
}

// 获取员工列表
export function staffList(params) {
	return request({ url: '/staff/staffList',method: 'get',data: params})
}

// 添加账号
export function addStaff(params) {
	return request({ url: '/staff/addStaff',method: 'post',data: params})
}

// 删除账号
export function deleteStaff(params) {
	return request({ url: '/staff/deleteStaff',method: 'get',data: params})
}

// 编辑账号
export function editStaff(params) {
	return request({ url: '/staff/editStaff',method: 'post',data: params})
}

// 修改客户状态
export function changeCustomerStatus(params) {
	return request({ url: '/customer/changeCustomerStatus',method: 'get',data: params})
}

// 修改客户商机
export function editCustomerChance(params) {
	return request({ url: '/customer/editCustomerChance',method: 'post',data: params})
}

// 账号员工详情
export function staffDetail(params) {
	return request({ url: '/staff/staffDetail',method: 'get',data: params})
}

// 获取角色详情
export function roleDetail(params) {
	return request({ url: '/role/roleDetail',method: 'get',data: params})
}

// 获取登录账号的详情
export function myDetail(params) {
	return request({ url: '/staff/myDetail',method: 'get',data: params})
}

// 编辑角色
export function editRole(params) {
	return request({ url: '/role/editRole',method: 'post',data: params})
}

// 公告列表
export function noticeList(params) {
	return request({ url: '/notice/noticeList',method: 'get',data: params})
}

// 客户画像分布
export function customerPieChart(params) {
	return request({ url: '/home/customerPieChart',method: 'get',data: params})
}

// 本月新增客户趋势图
export function customerTendencyChart(params) {
	return request({ url: '/home/customerTendencyChart',method: 'get',data: params})
}

// 客户统计
export function statisticsCustomer(params) {
	return request({ url: '/home/statisticsCustomer',method: 'get',data: params})
}

// 首页操作日志
export function operationLogList(params) {
	return request({ url: '/home/operationLogList',method: 'get',data: params})
}

// 删除角色
export function deleteRole(params) {
	return request({ url: '/role/deleteRole',method: 'get',data: params})
}

//指定月份的待办事项数(2022-01)
export function scheduleNum(params) {
	return request({ url: '/schedule/scheduleNum',method: 'get',data: params})
}

//某天的事项列表
export function scheduleListByTime(params) {
	return request({ url: '/schedule/scheduleListByTime',method: 'get',data: params})
}

//获取企业信息
export function companyDetail(params) {
	return request({ url: '/company/companyDetail',method: 'get',data: params})
}

//添加项目
export function addProject(params) {
	return request({ url: '/project/addProject',method: 'post',data: params})
}

// 我的项目列表
export function projectList(params) {
	return request({ url: '/project/projectList',method: 'get',data: params})
}

// 团队项目列表（作为项目参与者）
export function projectListTeam(params) {
	return request({ url: '/project/projectListTeam',method: 'get',data: params})
}

// 项目详情
export function projectDetail(params) {
	return request({ url: '/project/projectDetail',method: 'get',data: params})
}

// 项目跟进记录
export function projectFollowList(params) {
	return request({ url: '/project/projectFollowList',method: 'get',data: params})
}

// 添加项目跟进记录
export function addProjectFollow(params) {
	return request({ url: '/project/addProjectFollow',method: 'post',data: params})
}

// 修改项目
export function editProject(params) {
	return request({ url: '/project/editProject',method: 'post',data: params})
}

// 添加项目合伙人
export function addProjectPartner(params) {
	return request({ url: '/project/addProjectPartner',method: 'post',data: params})
}

// 删除项目某个合伙人
export function deleteProjectPartner(params) {
	return request({ url: '/project/deleteProjectPartner',method: 'post1',data: params})
}

// 项目阶段列表
export function stageList(params) {
	return request({ url: '/stage/stageList',method: 'get',data: params})
}

// 添加项目阶段
export function addProjectStage(params) {
	return request({ url: '/stage/addProjectStage',method: 'post',data: params})
}

// 编辑项目阶段
export function editProjectStage(params) {
	return request({ url: '/stage/editProjectStage',method: 'post',data: params})
}

// 删除项目阶段
export function deleteProjectStage(params) {
	return request({ url: '/stage/deleteProjectStage',method: 'get',data: params})
}

// 验收计划列表
export function projectCheckList(params) {
	return request({ url: '/check/projectCheckList',method: 'get',data: params})
}

// 附件列表
export function projectDocList(params) {
	return request({ url: '/doc/projectDocList',method: 'get',data: params})
}

// 某个项目的所有阶段
export function stageListAll(params) {
	return request({ url: '/stage/stageListAll',method: 'get',data: params})
}

// 修改项目阶段	
export function changeProjectStage(params) {
	return request({ url: '/project/changeProjectStage',method: 'post1',data: params,type: 1})
}

// 添加项目验收计划
export function addProjectCheck(params) {
	return request({ url: '/check/addProjectCheck',method: 'post',data: params})
}

// 编辑项目验收计划
export function editProjectCheck(params) {
	return request({ url: '/check/editProjectCheck',method: 'post',data: params})
}

// 删除项目验收计划
export function deleteProjectCheck(params) {
	return request({ url: '/check/deleteProjectCheck',method: 'get',data: params})
}

// 项目附件上传
export function uploadProjectDoc(params) {
	return request({ url: '/doc/uploadProjectDoc',method: 'post1',data: params})
}

// 编辑合同
export function editContract(params) {
	return request({ url: '/contract/editContract',method: 'post',data: params})
}


// 删除合同
export function deleteContract(params) {
	return request({ url: '/contract/deleteContract',method: 'get',data: params})
}

// 任务列表
export function taskList(params) {
	return request({ url: '/task/taskList',method: 'get',data: params})
}

// 添加项目任务
export function addProjectTask(params) {
	return request({ url: '/task/addProjectTask',method: 'post',data: params})
}

// 任务详情
export function projectTaskDetail(params) {
	return request({ url: '/task/projectTaskDetail',method: 'get',data: params})
}

// 删除项目任务
export function deleteProjectTask(params) {
	return request({ url: '/task/deleteProjectTask',method: 'get',data: params})
}


export function uploadProject(params) {
	return request({ url: '/project/uploadProject',method: 'post',data: params})
}






