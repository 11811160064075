import Vue from 'vue'
import VueRouter from 'vue-router'
import store from "../store/index.js"
import routes from "./routelist.js"
import "../utils/index.js"
Vue.use(VueRouter)


import {
	userPermissionTree
} from "@/network/api.js"


const router = new VueRouter({
	routes
})
const golist = ['ghCluesdetail', 'ghShangjiDetail', 'ghCustomerDetail', 'cluesDetail', 'shangjiDetail',
	'customerDetail',
	'addJuese', 'editJuese','projectDetail','projectOverview'
]

// 无需进行权限验证的路径
const noverifylist = ['/home','/crm','/project','/company','/organization']
// 路由导航守卫，前置钩子
router.beforeEach((to, from, next) => {
	
	if (to.path === '/login') {   // 如果是跳转登录页，无需做出权限判断
		next()
	} else if (!localStorage.getItem("adminToken")) { // 如果没有登录，则跳转到登录页
		next('/login')
	} else if (noverifylist.includes(to.path) && !to.meta.redirectlist) {  // 登录后无需进行权限验证,无需重定向的路径
		next()
	} else if (store.state.permissionList.length < 1 && !Vue.prototype.$isAdmin()) {  // 如果没有进行权限的获取
		store.dispatch('_userPermissionTree',() => {
			next(to.path)
		})
	} else if (Vue.prototype.$isAdmin() && !to.meta.redirectlist) {   //  如果是总账号,且不需要重定向
		next()
	} else if (Vue.prototype.$isAdmin() && to.meta.redirectlist) {   //  如果是总账号,需要重定向的路由
		next(to.meta.redirectlist[0].path)
			
		
	} else if (noverifylist.includes(to.path) && to.meta.redirectlist) {   // 需要重定向的路由地址,普通账号
		// console.log(to.meta.redirectlist);
		for (let value of to.meta.redirectlist) {
			if (store.state.permissionList.includes(value.name)) {
				next(value.path)
				break
			}
		}
	}else if (store.state.permissionList.includes(to.name)) {    // 无需重定向的地址直接判断是否有权限
		next()
	} else if (golist.includes(to.name)) {   //  权限名称和路由名称不同，另外判断
		next()
	} else {
		// next('/login')
	}
})

router.afterEach((to, from) => {
  // console.log(to,from);
	let formurl = to.path
	document.title = to.meta.text
	// if (formurl == '/company') {
	// 	store.commit('setTopIndex', 3)
	// }
	
})
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

export default router
