import Vue from 'vue'
import Vuex from 'vuex'
import {
	Loading
} from 'element-ui';
import systemModule from "./system.js"
import routeList from "../router/routelist.js"
Vue.use(Vuex);
Vue.use(Loading.directive);
import {
	login,
	allSettingList,
	userPermissionTree,
	myDetail
} from "@/network/api.js"

const $loading = Loading.service;
import "../utils/index.js"
export default new Vuex.Store({
	state: {
		topIndex: -1,
		userInfo: {},
		permissionList: [], // 权限名称列表
		isadmin: false, // 是否是总账号
		routerList: [], // 一级栏目列表
		projectStatus: ['原型设计','UI设计','开发','测试','交付','已验收'],
	},
	mutations: {
		setTopIndex(state,index) {
			
			state.topIndex = index
		},
		setUserInfo(state, info) {
			state.userInfo = info
		},
		// 设置一级权限
		setPermission(state, info) {
			state.permissionList = info
		},
		// 是否是总账号
		setAdmin(state, info) {
			state.isadmin = info
		},
		setRouterHome(state, routerList) {

		},

		// 获取路由列表
		getRouterList(state,path) {
			
			let toplist = routeList[0].children.filter(item => item.meta.level == 1)
			// console.log(toplist);
			toplist.forEach((item,index) => {
				if (item.path == path) {
					state.topIndex = index
				} else if (item.meta.childrenPath && item.meta.childrenPath.includes(path)) {
					state.topIndex = index
				}
			})
			// router.options.routes.filter(item => item.path === '/')[0].children.filter(item => {
			// 	if (item.meta && item.meta.level === 1) {
			// 		if (item.name == 'Home') {
			// 			this.routerList.push(item);
			// 		} else if (this.permissionList.includes(item.name)) {
			// 			this.routerList.push(item);
			// 		}
			// 	}
			// });
		
		}
	},

	actions: {

		// 获取权限
		 async _userPermissionTree({
			commit
		},callback) {
			// console.log('store permiss');
			// return
			const res = await userPermissionTree()
			// console.log('角色权限',res);
			let list = []
			res.data.forEach(item => {
				list.push(item.permissionName)
				if (item.permissionChildren && item.permissionChildren.length > 0) {
					item.permissionChildren.forEach(item1 => {
						list.push(item1.permissionName)
						if (item1.permissionChildren && item1.permissionChildren.length >
							0) {
							item1.permissionChildren.forEach(item2 => {
								list.push(item2.permissionName)
							})
						}
					})
				}
			})
			
			// 获取一级权限列表
			commit("setPermission", list)
			if (res.data.length === 0) {
				commit("setAdmin", true)
			}
			
			if (callback && typeof callback === 'function') {
				
				callback()
			}
		},
		// 获取登录账号详情
		async _myDetail({
			commit
		}) {
			const res = await myDetail()
			// console.log("账号详情",res);
			commit("setUserInfo", res.data)
		},
		// 登录
		_login({
			commit,
			dispatch
		}, userinfo) {
			const loading = $loading({ // 登录是的loading
				lock: true,
				text: 'Loading',
				spinner: 'el-icon-loading',
				background: 'rgba(0, 0, 0, 0.7)'
			});
			setTimeout(function() {
				loading.close();
			}, 5000);
			return new Promise((resolve, reject) => {
				login(userinfo).then(res => {
					console.log(res);
					loading.close();
					if (res.status !== 1) return
					commit("setUserInfo", res.data)
					localStorage.setItem('adminToken', res.data.token)
					localStorage.setItem('isStaff', res.data.isStaff)
					resolve(res)
					dispatch('_myDetail')

				})
			})
		},
	},
	modules: {
		systemModule
	}
})
