import Vue from 'vue'

import store from "../store/index.js"

import {
	Message
} from 'element-ui';

function myMessage(message, type) {
	Message({
		showClose: true,
		message,
		type
	});
}
if (process.env.NODE_ENV == 'production') {
	Vue.prototype.$baseUrl = "http://demo.mojingkj.cn/crm"
  } else {
		Vue.prototype.$baseUrl = '/api'
  }
	
	
	// 权限数据扁平化处理
	// function formatGetPermissionName(info) {
	// 	let list = info.map(item => {
			
	// 		if (item.permissionChildren && item.permissionChildren.length > 0) {
	// 			formatGetPermissionName(item.permissionChildren)
	// 		}
	// 		return item.permissionName
	// 	})
	// }
const isAdmin = () => {
	return store.state.isadmin && localStorage.getItem('isStaff') === '0'
	
	
}
Vue.prototype.$myMessage = myMessage
Vue.prototype.$isAdmin = isAdmin