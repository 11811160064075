import {login,allSettingList,userPermissionTree,myDetail} from "@/network/api.js"

export default {
	namespaced: true,
	state: {
		companyTypeList: [], // 公司类型列表
		companyIndustryList: [], // 公司行业列表
		cluesSourceList: [], // 线索来源列表
		customerType: [], // 客户标签
		genjinType: [], // 跟进方式
		customerStatusList: [], // 客户状态
		contractType: [],   // 合同类型
		payType: [],   // 付款方式
	},
	mutations: {
		setCompanyTypeList(state, info) {
			state.companyTypeList = info
		},
		setCompanyIndustryList(state, info) {
			state.companyIndustryList = info
		},
		setCluesSourceList(state, info) {
			state.cluesSourceList = info
		},
		setCustomerType(state, info) {
			state.customerType = info
		},
		setGenjinType(state, info) {
			state.genjinType = info
		},
		setCustomerStatus(state, info) {
			state.customerStatusList = info
		},
		setContractType(state, info) {
			state.contractType = info
		},
		setPayType(state, info) {
			state.payType = info
		},
	},

	actions: {
		// 获取标签
		async _allSettingList({commit}) {
			const res = await allSettingList();
			if (!res.data) {
				res.data = []
			}
			commit("setCompanyTypeList", res.data[1] || [])
			commit("setCompanyIndustryList", res.data[2] || [])
			commit("setCluesSourceList", res.data[3] || [])
			commit("setCustomerType", res.data[4] || [])
			commit("setGenjinType", res.data[5] || [])
			commit("setContractType", res.data[6] || [])
			commit("setPayType", res.data[7] || [])
			commit("setCustomerStatus", res.data[8] || [])
			// console.log('标签', res);
		},
	}
}

// export default systemModule
