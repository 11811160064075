import axios from 'axios'
import {
	Message,
	MessageBox
} from 'element-ui'
import store from '../store'
import router from '../router'
//请求地址
/*第一层if判断生产环境和开发环境*/
if (process.env.NODE_ENV == 'production') {
	axios.defaults.baseURL = "http://demo.mojingkj.cn/crm"
} else {
	axios.defaults.baseURL = '/api'
}

// axios.defaults.baseURL = 'http://192.168.10.3:3001/crm'
//设置超时时间
axios.defaults.timeout = 5000
// post请求头
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded'
axios.defaults.withcredentials = true

//请求拦截(请求发出前处理请求)
axios.interceptors.request.use((config) => {
	const token = localStorage.getItem("adminToken")
	if (token) {
		config.headers['token'] = token
	}
	return config;
}, (error) => {
	return Promise.reject(error);
});

// 响应拦截器（处理响应数据）
axios.interceptors.response.use(
	(res) => {
		const ret = res.data
		if (ret.status !== 1 && res.config.url!='/project/uploadProject' ) {
			// if(false){
				
			if (ret.status == 10000) {
				
				MessageBox.confirm(ret.msg, '系统提示', {
					confirmButtonText: '重新登录',
					type: 'warning'
				}).then(() => {
				
					localStorage.setItem('adminToken', '');
					router.replace({
						path: '/login'
					});
				})
			} else {
				
				Message({
					showClose: true,
					message: ret.message || 'Server error',
					type: 'error',
					duration: 2000
				})
				
				if (res.config.url === '/common/login') {  // 登录接口返回信息
					return res
				}
			}
			reject(ret.msg)
		} else {
			return res
		}
		
	},
	(error) => {
		Message({
			showClose: true,
			message: error.message,
			type: 'error',
			duration: 2000
		})
		return Promise.reject(error);
	}
);

// // 封装get方法
// function get(url, data){
//     console.log(data);
//     return new Promise((resolve, reject) =>{
//         axios.get(url, data).then(res =>{
//             resolve(res.data);
//         }).catch(err =>{
//             reject(err.data);
//         })
//     });
// }


// get请求拼接
function params(obj) {
	let result = '';
	let item;
	for (item in obj) {
		if (obj[item] && String(obj[item])) {
			result += `&${item}=${obj[item]}`;
		}
	}
	if (result) {
		result = '&' + result.slice(1)
	}
	return result
}

// 封装get方法
function get(url, data) {
	let result = '';
	let item;
	for (item in data) {
		if (data[item] && String(data[item])) {
			result += `&${item}=${data[item]}`;
		}
	}
	if (result) {
		result = '?' + result.slice(1)
	}
	url = url + result
	return new Promise((resolve, reject) => {
		axios.get(url).then(res => {
			resolve(res.data);
		}).catch(err => {
			reject(err.data);
		})
	});
}



// 封装post方法
function post(url, params) {
	return new Promise((resolve, reject) => {
		axios.post(url, params).then(res => {
			resolve(res.data);
		}).catch(err => {
			reject(err.data);
		})
	});
}

// 封装post方法
function post1(url, params) {

	return new Promise((resolve, reject) => {
		axios.post(url+params).then(res => {
			resolve(res.data);
		}).catch(err => {
			reject(err.data);
		})
	});
}

//对外接口
export function request({
	url,
	method,
	data,
	backend,
}) {
	if (method == 'get') {
		
		return get(url, data);
	} else if (method == 'post') {
		return post(url, data);
	} else if (method == 'post1') {
		return post1(url,data.data);
	}
}

export default request
