const Home = () => import('../views/home/Home.vue')
const CRM = () => import('../views/crm/crm.vue')
const Login = () => import('../views/login/Login.vue')
const Base = () => import('../views/base.vue')
const gonghaiManage = () => import('../views/gonghai/gonghaiManager.vue')
const ghCluesdetail = () => import('../views/gonghai/ghCluesDetail.vue')
const ghShangjiDetail = () => import('../views/gonghai/ghShangjiDetail.vue')
const ghCustomerDetail = () => import('../views/gonghai/ghCustomerDetail.vue')
const cluesManager = () => import('../views/cluesmanager/cluesManager.vue')
const cluesDetail = () => import('../views/cluesmanager/cluesDetail.vue')
const shangjiManage = () => import('../views/shangjimanage/shangjiManage.vue')
const shangjiDetail = () => import('../views/shangjimanage/shangjiDetail.vue')
const customerManage = () => import('../views/customermanage/customerManage.vue')
const customerDetail = () => import('../views/customermanage/customerDetail.vue')
const scheduleManage = () => import('../views/schedulemanage/scheduleManage.vue')
const contractManage = () => import('../views/contractmanage/contractManage.vue')
const Organization = () => import('../views/organization/Organization.vue')
const bumengManage = () => import('../views/organization/children/bumengManage.vue')
const roleManage = () => import('../views/organization/children/roleManage.vue')
const zhiwuManage = () => import('../views/organization/children/zhiwuManage.vue')
const jueseManage = () => import('../views/organization/children/jueseManage.vue')
const zhanghaoManage = () => import('../views/organization/children/zhanghaoManage.vue')
const addJuese = () => import('../views/organization/children/addJuese.vue')
const editJuese = () => import('../views/organization/children/editJuese.vue')
const projectManage = () => import('../views/projectmanage/projectManage')
const systemSet = () => import('../views/systemset/systemSet')
const projectOverview = () => import('../views/projectmanage/projectOverview')
const Company = () => import('../views/company/Company');
const companyManage = () => import('../views/company/companyManage');
const editPassword = () => import('../views/home/editPassword')
const projectDetail = () => import('../views/projectmanage/projectdetail/projectDetail')
const Project = () => import('../views/project/project')
let routes = [{
		path: '/',
		name: 'Base',
		component: Base,
		redirect: "/login",
		children: [{
				path: '/home',
				name: 'Home',
				component: Home,
				meta: {
					text: '首页',
					level: 0,
				}
			},

			{
				path: '/crm',
				name: 'CRM',
				component: CRM,
				// redirect: '/cluesmanager',
				meta: {
					text: 'CRM',
					level: 0,
					redirectlist: [{
						path: '/cluesmanager',
						name: 'cluesManage'
					},
					{
						path: '/shangjimanage',
						name: 'shangjiManage'
					},
					{
						path: '/gonghaimanage',
						name: 'gonghaiManage'
					},
					{
						path: '/customermanage',
						name: 'customerManage'
					},
					{
						path: '/schedulemanage',
						name: 'scheduleManage'
					},
					{
						path: '/organization',
						name: 'Organization'
					},
					{
						path: '/systemset',
						name: 'Systemset'
					}],
				},
				
				children: [{
						path: '/ghcluesdetail',
						name: 'ghCluesdetail',
						component: ghCluesdetail,
						meta: {
							text: '线索详情',
						}
					},
					{
						path: '/ghshangjiDetail',
						name: 'ghShangjiDetail',
						component: ghShangjiDetail,
						meta: {
							text: '商机详情',
						}
					},
					{
						path: '/ghcustomerDetail',
						name: 'ghCustomerDetail',
						component: ghCustomerDetail,
						meta: {
							text: '客户详情',
						}
					},			
					{
						path: '/cluesmanager',
						name: 'cluesManage',
						component: cluesManager,
						meta: {
							text: '线索',
							level: 1,
							childrenPath: ['/cluesdetail'],
							icon: require('@/assets/iconlist/cluesicon.png'),
							activeicon: require('@/assets/iconlist/cluesicona.png'),
						}
					},
					{
						path: '/cluesdetail',
						name: 'cluesDetail',
						component: cluesDetail,
						meta: {
							text: '线索详情',
						}
					},
					{
						path: '/shangjimanage',
						name: 'shangjiManage',
						component: shangjiManage,
						meta: {
							text: '商机',
							level: 1,
							childrenPath: ['/shangjidetail'],
							icon: require('@/assets/iconlist/shangjiicon.png'),
							activeicon: require('@/assets/iconlist/shangjiicona.png'),
						}
					},
					{
						path: '/shangjidetail',
						name: 'shangjiDetail',
						component: shangjiDetail,
						meta: {
							text: '商机详情',
						}
					},
					{
						path: '/gonghaimanage',
						name: 'gonghaiManage',
						component: gonghaiManage,
						meta: {
							text: '公海',
							level: 1,
							childrenPath: ['/ghcluesdetail', '/ghshangjiDetail', '/ghcustomerDetail'],
							icon: require('@/assets/iconlist/gonghaiicon.png'),
							activeicon: require('@/assets/iconlist/gonghaiicona.png'),
						}
					},
					
					{
						path: '/customermanage',
						name: 'customerManage',
						component: customerManage,
						meta: {
							text: '客户',
							level: 1,
							childrenPath: ['/customerdetail'],
							icon: require('@/assets/iconlist/custormicon.png'),
							activeicon: require('@/assets/iconlist/custormicona.png'),
						}
					},
					{
						path: '/customerdetail',
						name: 'customerDetail',
						component: customerDetail,
						meta: {
							text: '客户详情',
						}
					},
					{
						path: '/schedulemanage',
						name: 'scheduleManage',
						component: scheduleManage,
						meta: {
							text: '日程',
							level: 1,
							icon: require('@/assets/iconlist/scheduleicon.png'),
							activeicon: require('@/assets/iconlist/scheduleicona.png'),
						}
					},

					// {
					// 	path: '/organization',
					// 	name: 'Organization',
					// 	component: Organization,
					// 	meta: {
					// 		text: '组织',
					// 		icon: require('@/assets/iconlist/organizationicon.png'),
					// 		activeicon: require('@/assets/iconlist/organizationicona.png'),
					// 		level: 1,
					// 		childrenPath: ['/organization/bumengmanage', '/organization/juesemanage',
					// 			'/organization/zhanghaomanage', '/organization/addjuese'
					// 		],
					// 		redirectlist: [{
					// 				name: 'bumengManage',
					// 				path: '/organization/bumengmanage'
					// 			},
					// 			{
					// 				name: 'roleManage',
					// 				path: '/organization/rolemanage'
					// 			},
					// 			{
					// 				name: 'jueseManage',
					// 				path: '/organization/juesemanage'
					// 			}
					// 		],
					// 		nameList: [{
					// 				name: 'bumengManage',
					// 				path: '/organization/bumengmanage'
					// 			},
					// 			{
					// 				name: 'roleManage',
					// 				path: '/organization/rolemanage'
					// 			},
					// 			{
					// 				name: 'jueseManage',
					// 				path: '/organization/juesemanage'
					// 			}
					// 		]
					// 	},
					// 	children: [{
					// 			path: '/organization/bumengmanage',
					// 			name: 'bumengManage',
					// 			component: bumengManage,
					// 			meta: {
					// 				text: '部门管理',
					// 				level: 2,
					// 			},
					// 		},
					// 		{
					// 			path: '/organization/rolemanage',
					// 			name: 'roleManage',
					// 			component: roleManage,
					// 			meta: {
					// 				text: '权限管理',
					// 				level: 2,
					// 			},
					// 		},
					// 		{
					// 			path: '/organization/zhiwumanage',
					// 			name: 'zhiwuManage',
					// 			component: zhiwuManage,
					// 		},
					// 		{
					// 			path: '/organization/juesemanage',
					// 			name: 'jueseManage',
					// 			component: jueseManage,
					// 			meta: {
					// 				text: '角色管理',
					// 				level: 2,
					// 			},
					// 		},
					// 		{
					// 			path: '/organization/zhanghaomanage',
					// 			name: 'zhanghaoManage',
					// 			component: zhanghaoManage,
					// 			meta: {
					// 				text: '账号管理',
					// 				level: 2,
					// 			},
					// 		},
					// 		{
					// 			path: '/organization/addjuese',
					// 			name: 'addJuese',
					// 			component: addJuese,
					// 			meta: {
					// 				text: '添加角色',
					// 			}
					// 		},
					// 		{
					// 			path: '/organization/editjuese',
					// 			name: 'editJuese',
					// 			component: editJuese,
					// 			meta: {
					// 				text: '编辑角色',
					// 			}
					// 		}
					// 	]
					// },
					// {
					// 	path: '/systemset',
					// 	name: 'systemSet',
					// 	component: systemSet,
					// 	meta: {
					// 		text: '系统',
					// 		icon: require('@/assets/iconlist/xitong.png'),
					// 		activeicon: require('@/assets/iconlist/xitonga.png'),
					// 		level: 1,
					// 	},
					// },
				]
			},
			{
				path: '/project',
				name: 'Project',
				component: Project,
				meta: {
					
					text: '项目管理',
					level: 0,
					redirectlist: [{
						name: 'projectManage',
						path: '/projectmanage'
					},{
						name: 'contractManage',
						path: '/contractmanage'
					}]
				},
				children: [{
						path: '/projectmanage',
						name: 'projectManage',
						component: projectManage,
						redirect: '/projectmanage/overview',
						meta: {
							text: '项目',
							level: 3,
							childrenPath: ['/projectmanage/overview', '/projectdetail'],
							icon: require('@/assets/iconlist/projecticon.png'),
							activeicon: require('@/assets/iconlist/projecticona.png'),
							nameList: [{
								name: 'projectOverview',
								path: '/projectmanage/overview'
							}]
						},
						children: [{
							path: '/projectmanage/overview',
							name: 'projectOverview',
							component: projectOverview,
							meta: {
								text: '概览',
								// level: 2,
							}
						}]
					},
					{
						path: '/projectdetail',
						name: 'projectDetail',
						component: projectDetail,
						meta: {
							text: '项目详情',
					
						}
					},
					{
						path: '/contractmanage',
						name: 'contractManage',
						component: contractManage,
						meta: {
							icon: require('@/assets/iconlist/contracticon.png'),
							activeicon: require('@/assets/iconlist/contracticona.png'),
							text: '合同',
							level: 3,
						}
					},
				]
			},
			{
				path: '/editpassword',
				name: 'editPassword',
				component: editPassword,
				meta: {
					text: '修改密码',
				}
			},

			{
				path: '/company',
				name: 'Company',
				component: Company,
				meta: {
					text: '企业中心',
					level: 0,
					redirectlist: [{
						name:'companyManage',
						path:'/companyManage'
					},
					{
						path:'/organization',
						name:'Organization'
					},
					{
						path:'/systemset',
						name:'systemSet'
					}
					]
					
				},
				children: [
					{
						path: '/companyManage',
						name: 'companyManage',
						component: companyManage,
						meta: {
							icon: require('@/assets/iconlist/contracticon.png'),
							activeicon: require('@/assets/iconlist/contracticona.png'),
							text: '公司',
							level: 3,
						}
					},
					{
						path: '/organization',
						name: 'Organization',
						component: Organization,
						meta: {
							text: '组织',
							icon: require('@/assets/iconlist/organizationicon.png'),
							activeicon: require('@/assets/iconlist/organizationicona.png'),
							level: 3,
							childrenPath: ['/organization/bumengmanage', '/organization/juesemanage',
								'/organization/zhanghaomanage', '/organization/addjuese'
							],
							redirectlist: [{
									name: 'bumengManage',
									path: '/organization/bumengmanage'
								},
								{
									name: 'roleManage',
									path: '/organization/rolemanage'
								},
								{
									name: 'jueseManage',
									path: '/organization/juesemanage'
								}
							],
							nameList: [{
									name: 'bumengManage',
									path: '/organization/bumengmanage'
								},
								{
									name: 'roleManage',
									path: '/organization/rolemanage'
								},
								{
									name: 'jueseManage',
									path: '/organization/juesemanage'
								}
							]
						},
						children: [{
								path: '/organization/bumengmanage',
								name: 'bumengManage',
								component: bumengManage,
								meta: {
									text: '部门管理',
									level: 2,
								},
							},
							{
								path: '/organization/rolemanage',
								name: 'roleManage',
								component: roleManage,
								meta: {
									text: '权限管理',
									level: 2,
								},
							},
							{
								path: '/organization/zhiwumanage',
								name: 'zhiwuManage',
								component: zhiwuManage,
							},
							{
								path: '/organization/juesemanage',
								name: 'jueseManage',
								component: jueseManage,
								meta: {
									text: '角色管理',
									level: 2,
								},
							},
							{
								path: '/organization/zhanghaomanage',
								name: 'zhanghaoManage',
								component: zhanghaoManage,
								meta: {
									text: '账号管理',
									level: 2,
								},
							},
							{
								path: '/organization/addjuese',
								name: 'addJuese',
								component: addJuese,
								meta: {
									text: '添加角色',
								}
							},
							{
								path: '/organization/editjuese',
								name: 'editJuese',
								component: editJuese,
								meta: {
									text: '编辑角色',
								}
							}
						]
					},
					{
						path: '/systemset',
						name: 'systemSet',
						component: systemSet,
						meta: {
							text: '系统',
							icon: require('@/assets/iconlist/xitong.png'),
							activeicon: require('@/assets/iconlist/xitonga.png'),
							level:3,
						},
					},
					// {
					// 	path: '/companyManage',
					// 	name: 'companyManage',
					// 	component: companyManage,
					// 	meta: {
					// 		icon: require('@/assets/iconlist/contracticon.png'),
					// 		activeicon: require('@/assets/iconlist/contracticona.png'),
					// 		text: '合同',
					// 		level: 3,
					// 	}
					// },
				]
				
			}
		]
	},
	{
		path: '/login',
		name: 'Login',
		component: Login,
		meta: {
			text: '登录',
		},
	}
]

export default routes
